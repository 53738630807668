
import '@/assets/createProductWizzard.css';
import StepsNavigation from '@/components/CreateProductWizzard/StepsNavigation.vue';
import { Product, ProductRegion } from '@/models/Product';
import { WizzardStep } from '@/models/Enums';
import swal from 'sweetalert2';

import Multiselect from '@vueform/multiselect';
import { generalStore } from '@/store';

import { computed, watch, toRefs, ref } from 'vue';
import router from '@/router';
import useProduct from '@/modules/useProduct';
import { useI18n } from 'vue-i18n';

export default {
    props: {
        subStep: {
            type: String,
            required: true
        },
        productId: {
            type: String
        }
    },
    components: {
        Multiselect,
        StepsNavigation
    },
    async setup(props: any) {
        const { t } = useI18n();
        const showHelp = ref(false);
        const isNameExist = ref(false);

        const { saveProductWithResult, getProduct, getWizzardStep } = useProduct();
        const { productId } = toRefs(props);
        watch(productId, () => {
            if (!productId) generalStore.commit('setProduct', null);
        });

        if (productId.value) await getProduct(productId.value);
        else generalStore.commit('setProduct', null);

        const product = computed<Product>(() => generalStore.getters.getNewProduct);
        if (!generalStore.state.regions.length) await generalStore.dispatch('loadAllRegions');
        const allRegions = generalStore.state.regions;

        const productRegions = () => product.value.productRegions;
        function deleteRegion(region: ProductRegion) {
            product.value.productRegions.splice(product.value.productRegions.indexOf(region), 1);
        }

        const submitForm = async (
            prod: Product,
            event: any,
            nextStep: {
                stepName: string;
                subStep: string;
            }
        ) => {
            event.target.classList.add('was-validated');
            if (event.target.checkValidity() === false) {
                event.preventDefault();
                event.stopPropagation();
            } else {
                prod.wizzardStep = getWizzardStep('createProductStepOne', props.subStep);
                prod.fullDescriptionIndex = prod.fullDescription;
                const savedProductResult = await saveProductWithResult(prod);
                if (savedProductResult.data) {
                    router.push({
                        name: nextStep.stepName,
                        params: { subStep: nextStep.subStep, productId: savedProductResult.data.product.id }
                    });
                } else if (savedProductResult.error && savedProductResult.errorCode === 900) {
                    isNameExist.value = true;
                } else {
                    swal.fire({ icon: 'error', title: t('error-pop-up.oops'), text: savedProductResult.errorMessage });
                }
            }
        };

        const changeStep = async (stepName: string, subStep: string) => {
            router.push({ name: stepName, params: { subStep: subStep, productId: product.value.id } });
        };

        const cities = await generalStore.dispatch('getCities');

        const citySelect = {
            value: 0,
            placeholder: t('placeholder.selectCity'),
            valueProp: 'id',
            trackBy: 'name',
            label: 'name',
            required: true,
            searchable: true,
            options: cities
        };

        const addressRegionSelect = {
            value: 0,
            placeholder: t('address.region'),
            valueProp: 'id',
            trackBy: 'name',
            label: 'name',
            required: true,
            searchable: true,
            options: allRegions
        };
        return {
            product,
            productRegions,
            deleteRegion,
            citySelect,
            changeStep,
            submitForm,
            showHelp,
            isNameExist,
            addressRegionSelect
        };
    }
};
